<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-form ref="form" label-width="90px">
                    <el-row :gutter="10" class="top_l">
                        <el-col :span="24" :xs="24" class="elel">
                            <div class="readbox">
                                <el-tag size="large">查询是否可用：</el-tag>
                                <el-radio-group v-model="status" @change="radioes">
                                    <el-radio label="" border>全部</el-radio>
                                    <el-radio :label="1" border>是</el-radio>
                                    <el-radio :label="0" border>否</el-radio>
                                </el-radio-group>
                            </div>
                            <div>
                                <el-form-item label="提现类型" style="margin: 0;">
                                    <el-select v-model="type" clearable placeholder="请选择类型">
                                        <el-option :key="0" label="现金提现" :value="0" />
                                        <el-option :key="1" label="金币提现" :value="1" />
                                        <el-option :key="2" label="元宝提现" :value="2" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div style="margin-left: 20px;">
                                <el-button type="primary" @click="dialogAdd = true">新增</el-button>
                                <el-button type="primary" @click="jouShare">搜索</el-button>
                            </div>

                        </el-col>
                    </el-row>
                </el-form>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
                    stripe>
                    <el-table-column prop="id" label="ID" align="center" width="80"></el-table-column>
                    <el-table-column prop="productId" label="产品ID"></el-table-column>
                    <el-table-column label="类型">
                        <template v-slot="scope">
                            <span v-if="scope.row.type == 0">现金提现</span>
                            <span v-else-if="scope.row.type == 1">金币提现</span>
                            <span v-else-if="scope.row.type == 2">元宝提现</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" label="金额"></el-table-column>
                    <el-table-column prop="countLimit" label="总次数限制"></el-table-column>
                    <el-table-column label="是否可用">
                        <template v-slot="scope">
                            <el-switch @change="switchClick(scope.row)" v-model="scope.row.status"
                                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
                                :inactive-value="0" active-text="是" inactive-text="否" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                    <el-table-column label="操作" align="center" width="100">
                        <template v-slot="scope">
                            <el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
                <!-- 新增-配置 -->
                <el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="150px" class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="类型" prop="type">
                                    <el-select v-model="ruleForm.type" clearable placeholder="请选择类型">
                                        <el-option :key="0" label="现金提现" :value="0" />
                                        <el-option :key="1" label="金币提现" :value="1" />
                                        <el-option :key="2" label="元宝提现" :value="2" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="金额" prop="money">
                                    <el-input-number v-model="ruleForm.money" :precision="2" :step="0.01" :min="0.01"
                                        placeholder="请输入金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="总次数限制" prop="countLimit">
                                    <el-input-number v-model="ruleForm.countLimit" :step="1" :min="0"
                                        placeholder="请输入总次数限制" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
                            <el-button @click="resetClick('addruleForm')">重置</el-button>
                        </span>
                    </template>
                </el-dialog>
                <!-- 修改-配置 -->
                <el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="110px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="类型" prop="type">
                                    <el-select v-model="EditruleForm.type" clearable placeholder="请选择类型">
                                        <el-option :key="0" label="现金提现" :value="0" />
                                        <el-option :key="1" label="金币提现" :value="1" />
                                        <el-option :key="2" label="元宝提现" :value="2" />
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="金额" prop="money">
                                    <el-input-number v-model="EditruleForm.money" :precision="2" :step="0.01" :min="0.01"
                                        placeholder="请输入金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="总次数限制" prop="countLimit">
                                    <el-input-number v-model="EditruleForm.countLimit" :step="1" :min="0"
                                        placeholder="请输入总次数限制" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
                            <el-button @click="dialogEdit = false">取消</el-button>
                        </span>
                    </template>
                </el-dialog>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'withdrawalConfiguration',
    data() {
        return {
            is: false,
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',
            status: '',
            type: '',
            // 新增配置
            dialogAdd: false,
            ruleForm: {
                type: '',
                countLimit: 0,
                money: null,
            },
            rules: {
                type: [{
                    required: true,
                    message: '请选择类型',
                    trigger: 'change'
                }],
                countLimit: [{
                    required: true,
                    message: '请输入总次数限制',
                    trigger: 'blur'
                }],
                money: [{
                    required: true,
                    message: '请输入支付金额',
                    trigger: 'blur'
                }],


            },
            // 编辑
            dialogEdit: false,
            EditruleForm: {
                id: '',
                type: '',
                countLimit: 0,
                money: null,
            }
        };
    },
    created() {
        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }

        this.productId = this.$TOOL.data.get('DATA_SELECTEED')
        // 获取配置
        this.withdrawalConfiguration();
    },
    watch: {
        '$store.state.dataSelected'(newval) {
            if (this.productId != newval) {
                this.productId = newval
                // 获取配置
                this.withdrawalConfiguration();
            }
        }
    },
    methods: {
        // 获取配置
        withdrawalConfiguration() {
            this.serloading = true;
            this.$HTTP.post(
                'tradeWithdrawConfig/query', {
                status: this.status,
                productId: this.productId,
                pageNumber: this.currentPage,
                pageSize: this.pageSize,
                type: this.type
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                            this.serloading = false;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 搜索
        jouShare() {
            this.currentPage = 1;
            // 获取提现列表
            this.withdrawalConfiguration();
        },
        // 重置
        resetClick(name) {
            this.$refs[name].resetFields();
        },
        //查新是否可用
        radioes() {
            this.currentPage = 1;
            this.withdrawalConfiguration();
        },
        // 是否可用
        switchClick(row) {
            this.$HTTP.post(
                'tradeWithdrawConfig/updateStatus', {
                id: row.id,
                status: row.status
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.$message.success(response.data.msg);
                            // 获取配置
                            this.withdrawalConfiguration();
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 新增-配置
        submitClick(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'tradeWithdrawConfig/add', {
                        productId: this.productId,
                        type: this.ruleForm.type,
                        countLimit: this.ruleForm.countLimit,
                        money: this.ruleForm.money
                    },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.dialogAdd = false;
                                    this.$message.success(response.data.msg);
                                    this.resetClick(formName);
                                    // 获取配置
                                    this.withdrawalConfiguration();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        // 编辑
        clcedit(row) {
            this.EditruleForm.type = row.type;
            this.EditruleForm.countLimit = row.countLimit;
            this.EditruleForm.money = row.money;
            this.EditruleForm.id = row.id;
            this.dialogEdit = true;
        },
        EditsubmitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'tradeWithdrawConfig/update', {
                        id: this.EditruleForm.id,
                        productId: this.productId,
                        type: this.EditruleForm.type,
                        countLimit: this.EditruleForm.countLimit,
                        money: this.EditruleForm.money,
                    },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.dialogEdit = false;
                                    this.$message.success(response.data.msg);
                                    // 获取配置
                                    this.withdrawalConfiguration();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },

        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 获取配置
            this.withdrawalConfiguration();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 获取配置
            this.withdrawalConfiguration();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
    width: 100%;
}

.readbox {
    display: inline-block;

    span {
        margin-right: 10px;
        font-size: 16px;
    }
}

.elel {
    display: flex;
    align-items: center;
}
</style>